import '@naturehouse/nh-essentials/lib/polyfills/toggleAttribute';
import '@ungap/custom-elements';
import '@naturehouse/design-system/components/protons/icon/Icon';
import '@naturehouse/design-system/css/global.pcss';
import '@naturehouse/design-system/components/molecules/dialog/Dialog';
import '@naturehouse/design-system/components/atoms/notification/Notification';
import '@naturehouse/design-system/components/atoms/tool-tip/ToolTip';
import '@naturehouse/design-system/components/atoms/input/Input';
import '@naturehouse/design-system/components/atoms/anchor/Anchor';
import '@naturehouse/design-system/components/atoms/button/Button';
import '@naturehouse/design-system/components/atoms/navigation-list-item/NavigationListItem';
import '@naturehouse/design-system/components/molecules/form-field/FormField';
import '@naturehouse/design-system/components/atoms/image/Image';
import '@naturehouse/design-system/components/atoms/loader/Loader';
import '@naturehouse/design-system/components/molecules/table/Table';
import '@naturehouse/design-system/components/molecules/curtain/Curtain';
import '@naturehouse/design-system/components/molecules/banner/Banner';
import '@naturehouse/design-system/components/molecules/input-incrementer/InputIncrementer';
import '@naturehouse/design-system/components/molecules/navigation-dropdown/NavigationDropdown';
import '@naturehouse/design-system/components/atoms/chip/Chip';
import '@naturehouse/design-system/components/molecules/show-more/ShowMore';
import '@naturehouse/design-system/components/molecules/toggle-icon-button/ToggleIconButton';
import '@naturehouse/design-system/components/molecules/navigation-list/NavigationList';
import '@naturehouse/design-system/components/organisms/login-form/LoginForm';
import '@naturehouse/design-system/components/organisms/navigation/Navigation';
import '../../../css/app.pcss';
import ErrorLogger from '../../common/ErrorLogger';
import OldCurtain from '../../ui/Curtain';
import '../../ui/web-components/modal/Modal';
import Module from '../module';
import ModalComponent from '../shared/components/ModalComponent';
import RedirectPopup from './RedirectPopup';
import './components/NewsletterForm';
import './webComponents/Carousel';
import './webComponents/MobileCurtainContainer';
import './webComponents/StickyCTA';
import './webComponents/AddFavoriteButton';
import './webComponents/CSRFInput';

import googleAnalyticsIdManager from './GoogleAnalyticsIdManager';
import './FormField';
import LoginManager from './LoginManager';
import CookieConsentManager from './CookieConsentManager';
import ConvertReporter from '../../common/ConvertReporter';

export default class AppModule extends Module {
    constructor() {
        super();

        ErrorLogger.init();

        document.addEventListener('DOMContentLoaded', () => {
            this.#loadComponents();
            this.#hamburgerMenuAnimation();
            googleAnalyticsIdManager.initialize();
            ConvertReporter.track();

            const redirectPopup = new RedirectPopup();
            redirectPopup.initialize();

            LoginManager.getInstance();

            const cookieConsentManager = new CookieConsentManager();
            cookieConsentManager.initialize();
        });
    }

    #hamburgerMenuAnimation(): void {
        const element = document.querySelector('[data-role="hamburger-menu"]');
        if (!element) {
            return;
        }

        element.addEventListener('click', () => {
            element.classList.toggle('opened');
            element.setAttribute(
                'aria-expanded',
                element.classList.contains('opened') ? 'true' : 'false'
            );
        });
    }

    #loadComponents(): void {
        this.components.push(ModalComponent);
        this.components.push(OldCurtain);
        this.resolveComponents();
    }
}

(function appModuleInit(): void {
    const appModule = new AppModule();
    appModule.init();
})();
