import errorTemplate from '@templates/form_theme/ds/atoms/ErrorText.html.njk';
import Input, { InputProps } from '@naturehouse/design-system/components/atoms/input/Input';
import { SelectProps } from '@naturehouse/design-system/components/atoms/select/Select';
import { IconProps } from '@naturehouse/design-system/components/protons/icon/Icon';
import { DefaultComponentType } from '@naturehouse/design-system/utils/types';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';

export type SelectFormField = {
    select: SelectProps;
    label: string;
};

export enum FormFieldType {
    DEFAULT = 'default',
    RADIO = 'radio',
    DATE_FROM_TO = 'date-from-to',
    CHECKBOX = 'checkbox',
    SELECT = 'select',
    TEXTAREA = 'textarea'
}

export enum FormFieldEvents {
    IS_VALID = 'formFieldValidity'
}

type HelpProps = {
    text: string;
    secure: boolean;
    link: {
        url: string;
        label: string;
    };
};

export type FormFieldProps = DefaultComponentType & {
    type: FormFieldType;
    input: InputProps;
    label: string;
    help?: HelpProps;
    errorText?: string;
    icon?: IconProps;
    clearable?: boolean;
    minimal?: boolean;
    attached?: boolean;
};

const fieldSelector = '[is="nh-input"], [is="nh-select"], [is="nh-textarea"], [data-role="input"]';

export default class FormRow extends HTMLElement {
    #field: Input | HTMLSelectElement | HTMLTextAreaElement | null =
        this.querySelector(fieldSelector);

    get field(): Input | HTMLSelectElement | HTMLTextAreaElement | null {
        return this.#field;
    }

    readonly #labelContainer = this.querySelector(':scope > [container="label"]');

    readonly #inputContainer = this.querySelector(':scope > [container="input"]');

    readonly #errorContainer = this.querySelector(':scope > [container="error"]');

    readonly #helpContainer = this.querySelector(':scope > [container="help"]');

    readonly #preventErrorMessage: boolean =
        this.parentElement?.getAttribute('container') === 'input';

    readonly #describedByError = `${this.field?.id}-error-text`;

    readonly #describedByHelp = `${this.field?.id}-help-text`;

    #errorTemplate: HTMLParagraphElement | null = null;

    #clearable = false;

    #clearButton: HTMLButtonElement | null = null;

    #disabled: boolean = this.#field?.disabled ?? false;

    get disabled(): boolean {
        return this.#disabled;
    }

    set disabled(value: boolean) {
        this.#disabled = value;

        this.classList.toggle(FormRow.#disabledClass, value);

        if (this.#field) {
            this.#field.disabled = value;
        }

        if (value) {
            this.clearErrorState();
        }
    }

    static readonly #errorClass = 'nh-form-field--error';

    static readonly #disabledClass = 'nh-form-field--disabled';

    set clearable(clearable: boolean) {
        this.#clearable = clearable;
    }

    get clearable(): boolean {
        return this.#clearable;
    }

    get value(): string {
        return this.#field?.value || '';
    }

    get type(): FormFieldType | null {
        if (!this.#field) {
            return null;
        }

        if (this.#field instanceof HTMLTextAreaElement) {
            return FormFieldType.TEXTAREA;
        }

        if (this.#field instanceof HTMLSelectElement) {
            return FormFieldType.SELECT;
        }

        if (Object.values<string>(FormFieldType).includes(this.#field.type)) {
            return this.#field.type as FormFieldType;
        }

        return null;
    }

    constructor(props: FormFieldProps) {
        super();

        if (props && props.errorText) {
            this.classList.add(FormRow.#errorClass);
        }
    }

    public connectedCallback(): void {
        this.#field = this.querySelector(fieldSelector);
        this.disabled = this.#field?.disabled ?? false;
        this.#clearButton = this.querySelector('[data-role="clear"]');

        if (!this.#field) {
            return;
        }

        this.#clearButton?.addEventListener('click', this.#onClear);
        this.#field.addEventListener('cleared', this.#showOrHideClearButton);
        this.#field.addEventListener('input', this.#showOrHideClearButton);
        this.#field.addEventListener('blur', this.#showOrHideClearButton);
        this.#field.addEventListener('change', this.#onChange);
        this.#field.addEventListener('invalid', (event) => this.#onInvalid(event));
        this.#field.addEventListener('disabled', this.#onDisabled);

        this.#showOrHideClearButton();
    }

    public disconnectedCallback(): void {
        if (!this.#field) {
            return;
        }

        this.#clearButton?.removeEventListener('click', this.#onClear);
        this.#field.removeEventListener('cleared', this.#showOrHideClearButton);
        this.#field.removeEventListener('input', this.#showOrHideClearButton);
        this.#field.removeEventListener('blur', this.#showOrHideClearButton);
        this.#field.removeEventListener('change', this.#onChange);
        this.#field.removeEventListener('invalid', this.#onInvalid);
        this.#field.removeEventListener('disabled', this.#onDisabled);
    }

    public clearErrorState(): void {
        this.classList.remove(FormRow.#errorClass);
        this.#removeErrorMessages();
    }

    readonly #onDisabled = (): void => {
        this.disabled = this.#field?.disabled ?? false;
    };

    readonly #onClear = (event: MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        this.#field?.dispatchEvent(new Event('clear'));
    };

    readonly #onChange = (): void => {
        if (this.#field?.validity.valid) {
            this.#onValid();
        }

        this.#showOrHideClearButton();
    };

    readonly #showOrHideClearButton = (): void => {
        this.#clearButton?.toggleAttribute('hidden', !this.#field?.value);
        this.clearable = !!this.#field?.value;
    };

    readonly #onValid = (): void => {
        this.clearErrorState();

        this.dispatchEvent(
            new CustomEvent(FormFieldEvents.IS_VALID, { detail: { isValid: true }, bubbles: true })
        );
    };

    readonly #onInvalid = (event: Event): void => {
        let message = this.#field?.validationMessage || '';

        if (event instanceof CustomEvent && event.detail.message) {
            message = event.detail.message;
        }

        this.classList.add(FormRow.#errorClass);

        if (this.#preventErrorMessage) {
            return;
        }

        this.dispatchEvent(
            new CustomEvent(FormFieldEvents.IS_VALID, {
                detail: { isValid: false, message: message }
            })
        );

        this.addErrorMessage(message);
    };

    public addErrorMessage(message: string): void {
        if (this.#errorTemplate === null && message) {
            this.#errorTemplate = parseStringAsHtml(
                errorTemplate.render({
                    id: this.#describedByError,
                    message
                }),
                'p'
            ) as HTMLParagraphElement;

            this.#errorContainer?.append(this.#errorTemplate);
            this.#addAriaDescribedBy(this.#describedByError);
        }
    }

    readonly #removeErrorMessages = (): void => {
        if (this.#errorTemplate && !this.#field?.validationMessage) {
            this.#removeAriaDescribedBy(this.#describedByError);
            this.#errorContainer?.removeChild(this.#errorTemplate);
            this.#errorTemplate = null;
        }
    };

    readonly #addAriaDescribedBy = (value: string): void => {
        if (this.#field === null) {
            return;
        }

        const describedByValue = this.#field.getAttribute('aria-describedby');
        const describedByValues = describedByValue?.split(' ') || [];

        describedByValues.push(value);

        this.#field.setAttribute('aria-describedby', describedByValues.join(' '));
    };

    readonly #removeAriaDescribedBy = (value: string): void => {
        if (this.#field === null) {
            return;
        }

        const describedByValue = this.#field.getAttribute('aria-describedby');
        const describedByValues = describedByValue?.split(' ').filter((val) => val !== value) || [];

        if (describedByValues.length === 0) {
            this.removeAttribute('aria-describedby');
            return;
        }

        this.#field.setAttribute('aria-describedby', describedByValues.join(' '));
    };
}

if (!window.customElements.get('nh-form-row')) {
    window.customElements.define('nh-form-row', FormRow);
}
