import '../../../../css/components/_favoriteAdd.pcss';
import ToggleIconButton from '@naturehouse/design-system/components/molecules/toggle-icon-button/ToggleIconButton';
import HttpClient from '@naturehouse/nh-essentials/lib/requests/HttpClient';
import LoginDialog, { LoginState } from '../LoginDialog';
import GoogleAnalyticsEvents from '../../../common/GoogleAnalyticsEvents';
import GuestRegisterDialog from '../GuestRegisterDialog';

export default class AddFavoriteButton extends ToggleIconButton {
    readonly #favorited: boolean = this.hasAttribute('favorited');

    readonly #endpoint: string = this.getAttribute('endpoint') ?? '';

    public connectedCallback(): void {
        if (!this.#endpoint) {
            return;
        }

        super.connectedCallback();

        this.classList.toggle('disabled', this.#favorited);

        this.addEventListener('click', this.#handleClick.bind(this));
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEventListener('click', this.#handleClick.bind(this));
    }

    async #handleClick(): Promise<void> {
        const successCallback = async (): Promise<void> => {
            await this.#toggleFavorite();
        };

        if (document.body.classList.contains(LoginState.USER_AVAILABLE)) {
            await successCallback();
            return;
        }

        const loginDialog = LoginDialog.getInstance();
        const guestRegisterDialog = GuestRegisterDialog.getInstance();

        loginDialog.successCallback = successCallback;
        guestRegisterDialog.successCallback = successCallback;
        loginDialog.show();
    }

    async #toggleFavorite(): Promise<void> {
        this.loading = true;

        const response = await HttpClient.post(this.#endpoint);
        const favoriteResponse = response as unknown as FavoriteResponse;

        if (!favoriteResponse.success && favoriteResponse.notLoggedIn) {
            this.loading = false;
            const loginDialog = LoginDialog.getInstance();
            loginDialog.show();
            return;
        }

        if (!favoriteResponse.success) {
            return;
        }

        this.#trackUserFavorite(favoriteResponse);

        if (!favoriteResponse.is_favorite) {
            this.active = false;
            this.loading = false;
            return;
        }

        this.active = true;
        this.loading = false;
    }

    #trackUserFavorite(response: FavoriteResponse): void {
        const houseId = response.house_id;

        if (!houseId) {
            return;
        }

        GoogleAnalyticsEvents.toggleFavourite({
            action: response.is_favorite ? 'add' : 'remove',
            houseId: houseId
        });
    }
}

if (!customElements.get('nh-add-favorite')) {
    customElements.define('nh-add-favorite', AddFavoriteButton, { extends: 'div' });
}
