import '../../../css/components/_cookieContentDialog.pcss';
import { getCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import type MultiStepper from '@naturehouse/design-system/components/atoms/multi-stepper/MultiStepper';
import type MultiStepDialog from '@naturehouse/design-system/components/molecules/dialog/MultiStepDialog';
import { CookieConsentCookie, CookieConsentVersion } from './webComponents/CookieConsentDialog';

export default class CookieConsentManager {
    readonly #dialogToggleButtons: HTMLElement[] = Array.from(
        document.querySelectorAll('[data-role="cookie-consent-toggle"]')
    );

    readonly #multiStepDialog = document.querySelector('#cookie-consent-dialog') as MultiStepDialog;

    public async initialize(): Promise<void> {
        if (!this.#multiStepDialog) {
            return;
        }

        await import('./webComponents/CookieConsentDialog');

        for (const dialogToggleButton of this.#dialogToggleButtons) {
            dialogToggleButton.addEventListener('click', this.#openCookieConsentDialog);
        }

        this.#handleAutoOpen();
    }

    readonly #openCookieConsentDialog = async (): Promise<void> => {
        if (this.#multiStepDialog.element.open) {
            return;
        }

        const multiStepper = this.#multiStepDialog.querySelector('multi-stepper') as MultiStepper;

        if (multiStepper.state.currentStep === 1) {
            await this.#multiStepDialog.nextStep();
        }

        this.#multiStepDialog.showModal();
    };

    readonly #handleAutoOpen = (): void => {
        if (window.location.pathname === '/cookies') {
            return;
        }

        /*
            Brave browser tries and detect files/components which are not allowed to be used such as the cookie consent dialog.
            The word cookie is a trigger for this detection.

            We opted to not rename our components but simply omit this cookie consent dialog for Brave users.
            Since Brave is a privacy focused browser, it is likely that users are already aware of cookies and their usage and Brave will block them by default.
        */
        const isBrave = 'brave' in window.navigator;
        if (isBrave) {
            return;
        }

        const cookieValue = getCookieValue(CookieConsentCookie.NAME);

        if (cookieValue && cookieValue.includes(CookieConsentVersion.VERSION)) {
            return;
        }

        this.#multiStepDialog.showModal();
    };
}
