import MultiStepDialog from '@naturehouse/design-system/components/molecules/dialog/MultiStepDialog';
import dialogPolyfill from 'dialog-polyfill';
import '@naturehouse/design-system/components/atoms/multi-stepper/MultiStepper';
import Button, { ButtonVariant } from '@naturehouse/design-system/components/atoms/button/Button';
import { setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import Step, { StepEvents } from '@naturehouse/design-system/components/atoms/multi-stepper/Step';
import InitializationError from '@naturehouse/nh-essentials/lib/exceptions/InitializationError';
import GoogleAnalyticsEvent from '../../../common/GoogleAnalyticsEvents';

export enum CookieConsentCookie {
    NAME = 'cookie_consent_setting'
}

export enum CookieConsentTypeMode {
    GRANTED = 'granted',
    DENIED = 'denied'
}

export enum CookieConsentSaveReason {
    MODAL = 'modal',
    ACCEPT_ALL = 'accept_all'
}

/* Updating the version will invalidate cookie settings and
require the user to re-accept the cookie settings */
export enum CookieConsentVersion {
    VERSION = 'v2'
}

export default class CookieConsentDialog extends MultiStepDialog {
    protected nextButton = this.querySelector('[data-role="cookie-settings"]') as Button;

    readonly #saveCookieSettingsButtons: NodeListOf<Button> = this.querySelectorAll(
        '[data-role="save-cookie-settings"]'
    );

    readonly #cookieSettingsStep = this.querySelector('nh-step[step="2"]') as Step;

    #saveReason = CookieConsentSaveReason.ACCEPT_ALL;

    #consentData: GoogleAnalytics4ConsentModeEvent = {
        ad_personalization: CookieConsentTypeMode.DENIED,
        ad_storage: CookieConsentTypeMode.DENIED,
        ad_user_data: CookieConsentTypeMode.DENIED,
        analytics_storage: CookieConsentTypeMode.GRANTED,
        functionality_storage: CookieConsentTypeMode.GRANTED,
        personalization_storage: CookieConsentTypeMode.DENIED,
        security_storage: CookieConsentTypeMode.GRANTED
    };

    public connectedCallback(): void {
        super.connectedCallback();
        dialogPolyfill.registerDialog(this.element);

        if (
            this.nextButton === null ||
            this.#saveCookieSettingsButtons.length === 0 ||
            this.#cookieSettingsStep === null
        ) {
            throw new InitializationError();
        }

        this.#cookieSettingsStep.addEventListener(
            StepEvents.SHOWN,
            this.#handleSettingsStep.bind(this)
        );

        for (const button of this.#saveCookieSettingsButtons) {
            button.addEventListener('click', this.#saveCookieSettings.bind(this));
        }
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();

        this.#cookieSettingsStep.removeEventListener(
            StepEvents.SHOWN,
            this.#handleSettingsStep.bind(this)
        );

        for (const button of this.#saveCookieSettingsButtons) {
            button.removeEventListener('click', this.#saveCookieSettings.bind(this));
        }
    }

    public showModal(): void {
        super.showModal();

        GoogleAnalyticsEvent.cookieBannerImpression();
    }

    #handleSettingsStep(): void {
        GoogleAnalyticsEvent.cookieBannerModal();
        const primaryFooterButton: Button = this.querySelector(
            'footer button[variant="primary"]'
        ) as Button;

        primaryFooterButton.remove();

        (this.nextButton as Button).variant = ButtonVariant.PRIMARY;
        (this.nextButton as Button).setAttribute('data-role', 'save-custom-cookie-settings');
        (this.nextButton as Button).setAttribute('data-cy', 'save-custom-cookie-settings');
        const clone = this.nextButton.cloneNode(true) as Button;
        this.nextButton.replaceWith(clone);
        this.nextButton = clone;
        this.nextButton.addEventListener('click', this.#saveCustomCookieSettings.bind(this));
    }

    #saveCookieSettings(): void {
        this.#consentData = {
            ad_personalization: CookieConsentTypeMode.GRANTED,
            ad_storage: CookieConsentTypeMode.GRANTED,
            ad_user_data: CookieConsentTypeMode.GRANTED,
            analytics_storage: CookieConsentTypeMode.GRANTED,
            functionality_storage: CookieConsentTypeMode.GRANTED,
            personalization_storage: CookieConsentTypeMode.GRANTED,
            security_storage: CookieConsentTypeMode.GRANTED
        };

        this.close();
    }

    #saveCustomCookieSettings(): void {
        const form = this.querySelector('form') as HTMLFormElement;
        const formData = new FormData(form);

        const marketingCookieConsentType =
            formData.get('marketing-cookies') === 'on'
                ? CookieConsentTypeMode.GRANTED
                : CookieConsentTypeMode.DENIED;

        this.#consentData.ad_personalization = marketingCookieConsentType;
        this.#consentData.ad_storage = marketingCookieConsentType;
        this.#consentData.ad_user_data = marketingCookieConsentType;
        this.#consentData.personalization_storage = marketingCookieConsentType;

        this.#saveReason = CookieConsentSaveReason.MODAL;
        this.close();
    }

    public close(): void {
        super.close();

        const versionedConsentData = {
            ...this.#consentData,
            ...{ version: CookieConsentVersion.VERSION }
        };

        setCookieValue(CookieConsentCookie.NAME, JSON.stringify(versionedConsentData), {
            'max-age': 31536000
        });

        GoogleAnalyticsEvent.updateConsentMode(this.#consentData);
        GoogleAnalyticsEvent.cookieBannerSave(this.#consentData, this.#saveReason);
    }
}

if (!window.customElements.get('cookie-consent-dialog')) {
    window.customElements.define('cookie-consent-dialog', CookieConsentDialog);
}
